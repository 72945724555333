import React from "react"

import Project from "../../../components/project"
import SEO from "../../../components/seo"
import introImage from "../../../images/simple-scales/intro.png"
import iconImage from "../../../images/simple-scales/icon_color.svg"
import processImage from "../../../images/simple-scales/hero.png"
import technologyImage from "../../../images/simple-scales/table.jpg"

const IndexPage = () => (
  <Project>
    <SEO title="Home" />
    <section className="intro" style={{backgroundImage: `url(${introImage})`} }>
      <img src={iconImage} alt="" height="180" />
      <h2>Simple Scales</h2>
      <p>Simple Scales is an iPhone application that makes regularly enetering your weight into iOS HealthKit data store simple. The app uses a custom-designed input to make entering your data simple and seamless. In addition to saving your mass to HealthKit, Simple Scales also uses your height data to calculate and save your BMI.</p>   
      <a
        className="btn"
        target="_blank"
        rel="noopener noreferrer"
        href="https://itunes.apple.com/au/app/simple-scales-weight-tracker/id1144473563?mt=8"
      >
        Download Simple Scales for iOS
      </a>
    </section>
    <div className="content">
      <section>
        <h2>Process</h2>

        <p>With recent changes to the user interface of HealthKit, it became obvious how inconvenient adding your weight to HealthKit was if you weren’t using a smart scale, especially if you were tracking your weight daily.</p>
        <p>After brainstorming with an experience designer to figure out the ideal input method for updating a single number that didn’t significantly change in value, the scale slider was settled on. It allows the user to update their weight with the most recent incremental change. The current mass displayed to the user is also updated as the user scrolls, providing realtime feedback to the user. To give the input greater physicality the new haptics API was used to gently vibrate the phone as the user scrolled through the scale, or when a weight value is saved.</p>
        <p>Native HealthKit unit transformation was used to localise the input and saved data for the user no matter what region they were from.</p>

        <figure className="rounded-figure">
          <a
            target="_blank"
            alt=""
            rel="noopener noreferrer"
            href={processImage}
          >
            <img src={processImage} alt="" />
          </a>
          <figcaption>Simple Scales for iPhone and Apple Watch.</figcaption>
        </figure>
      </section>

      <section>
        <h2>Technology</h2>

        <p>iPhone application is coded completely in Swift using Xcode.</p>
        <p>The app uses the HealthKit API and data store extensively.</p>
        <p>A custom input method was created making use of a customed collection view.</p>
        <p>A dynamically created image using CoreGraphics was used to allow users to share their current weight with friends using the sharesheet.</p>
        <p>The application uses Git as its version control with issue tracking managed in Bitbucket and project management managed in Trello.</p>

        <figure className="rounded-figure">
          <a
            target="_blank"
            alt=""
            rel="noopener noreferrer"
            href={technologyImage}
          >
            <img src={technologyImage} alt="" />
          </a>
          <figcaption>Simple Scales is available for download on the iPhone App Store.</figcaption>
        </figure>
      </section>
    </div>
  </Project>
)

export default IndexPage
